import {
  SubscriptionPlan,
  UnifiedPlanDetails,
} from '@cointracker/graphql-types';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { Body5 } from '@cointracker/styleguide/src/LoggedIn/Typography';
import { Sparkle } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'src/app/analytics';
import { URLS } from 'src/common/urls';
import { useExperiment } from 'statsig-react';
import { UserContext } from '../../../../app/user/context';

interface TransactionLimitCardProps {
  loading: boolean;
  transactionCount: number;
  transactionLimit: number;
  recommendedPlanForTax: UnifiedPlanDetails;
  currentPlan: SubscriptionPlan;
}

export function TransactionLimitCard({
  loading,
  transactionCount,
  transactionLimit,
  recommendedPlanForTax,
  currentPlan,
}: TransactionLimitCardProps) {
  const analytics = useAnalytics();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const transactionLimitCardGateOn =
    useExperiment('persistent_card_v2').config.getValue('show');
  const daysLeftToFile = Math.max(0, dayjs('2025-04-16').diff(dayjs(), 'day'));
  const shouldShowTransactionLimitCard =
    user.isAuthenticated &&
    user.country.code === 'US' &&
    transactionLimitCardGateOn &&
    daysLeftToFile > 0 &&
    !loading &&
    transactionCount > transactionLimit;

  if (!shouldShowTransactionLimitCard) return null;

  const usagePercentage = Math.min(
    (transactionCount / transactionLimit) * 100,
    100,
  );

  const discountReason = recommendedPlanForTax?.price?.discountReason;

  const handleUpgradePlan = () => {
    analytics.track('TransactionLimitCard Clicked', {
      discountReason,
      transactionCount,
      transactionLimit,
    });
    navigate(URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE);
  };

  const renderContent = () => {
    if (currentPlan === SubscriptionPlan.Free) {
      return (
        <Body5 className="leading-tight mb-0 text-text-secondary">
          Only <span className="text-text-primary">{daysLeftToFile} days</span>{' '}
          left until April 15th! Upgrade and finish your taxes in ~10 minutes.
        </Body5>
      );
    } else {
      return (
        <div>
          <Body5 className="leading-tight mb-0 text-text-secondary">
            Only{' '}
            <span className="text-text-primary">{daysLeftToFile} days</span>{' '}
            left until April 15th!
          </Body5>
          <Body5 className="mb-4 text-text-secondary">
            Upgrade to get your tax forms.
          </Body5>
          <Body5 className="mb-6 text-text-link">
            {transactionCount}
            <span className="text-text-primary">/{transactionLimit}</span>
            <span className="text-text-secondary"> transactions</span>
          </Body5>

          <div className="bg-background-disabled mb-4 h-4 w-full overflow-hidden rounded-full">
            <div
              className="h-full rounded-full bg-text-link"
              style={{ width: `${usagePercentage}%` }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="mb-4 flex flex-row items-center gap-24 rounded-16 border border-line-tertiary bg-background-default p-10 shadow-sm md:flex-col md:gap-0">
      {renderContent()}
      <Button
        size="x-small"
        className="mt-8 whitespace-nowrap md:w-full"
        variant="highEmphasis"
        onClick={handleUpgradePlan}
      >
        <Sparkle weight="bold" className="hidden md:block" />
        Upgrade plan
      </Button>
    </div>
  );
}
