import SOCITypeII from '@cointracker/assets/general/soc-1-type-2.svg?react';
import SOCIITypeII from '@cointracker/assets/general/soc-2-type-2.svg?react';
import { REBRAND_URLS } from '@cointracker/ui';
import classNames from 'classnames';
import { cloneElement } from 'react';
import { Button } from '../Button';
import { NavLink } from '../NavLink';
import { Tag } from '../Tag';
import AppStoreIcon from '../icons/app-store.svg?react';
import CoinTrackerTextLogo from '../icons/cointracker-text-logo.svg?react';
import PlayStoreIcon from '../icons/play-store.svg?react';
import Facebook from '../icons/social/facebook-monochrome.svg?react';
import LinkedIn from '../icons/social/linkedin-monochrome.svg?react';
import Reddit from '../icons/social/reddit-monochrome.svg?react';
import X from '../icons/social/x-monochrome.svg?react';
import YouTube from '../icons/social/youtube-monochrome.svg?react';
import { type ChildAndClassNameProps } from '../types';
import { Body1, Body3, H5 } from '../typography';
import {
  appStoreButton,
  appStoreButtons,
  appStoreGroupedContent,
  appStoreSection,
  bottomNavigationList,
  bottomSection,
  bottomSectionInner,
  bottomSocialList,
  coinTrackerTextLogo,
  copyright,
  downloadSubheading,
  footer,
  footerGrid,
  navigationList,
  navigationListItem,
  navigationSection,
  socBadges,
  socialLink,
  topSection,
  topSectionTop,
} from './Footer.css';

export const Footer = () => {
  return (
    <footer className={footer}>
      <section className={topSection}>
        <FooterGrid className={topSectionTop}>
          <div className={navigationSection}>
            <FooterNavList
              items={primaryNavItems}
              variant="primary"
              sectionTitle="CoinTracker"
            />
            <FooterNavList
              items={secondaryNavItems}
              variant="primary"
              sectionTitle="Resources"
            />
            <FooterNavList
              items={tertiaryNavItems}
              variant="primary"
              sectionTitle="Company"
            />
            <div className={socBadges}>
              <SOCITypeII />
              <SOCIITypeII />
            </div>
          </div>
          <div className={appStoreSection}>
            <div className={appStoreGroupedContent}>
              <H5>Download our app</H5>
              <Body3 className={downloadSubheading}>
                View your return on investment, your unified transaction
                history, wallet balances, and much more on the go.
              </Body3>
            </div>
            <div className={appStoreButtons}>
              <Button
                href={REBRAND_URLS.APPLE_APP_STORE}
                variant="secondary"
                hideInitialIcon
                className={appStoreButton}
                icon={<AppStoreIcon />}
              >
                App store
              </Button>
              <Button
                href={REBRAND_URLS.GOOGLE_PLAY_STORE}
                variant="secondary"
                hideInitialIcon
                className={appStoreButton}
                icon={<PlayStoreIcon />}
              >
                Play store
              </Button>
            </div>
          </div>
        </FooterGrid>
        <FooterGrid>
          <CoinTrackerTextLogo className={coinTrackerTextLogo} />
        </FooterGrid>
      </section>
      <section className={bottomSection}>
        <FooterGrid className={bottomSectionInner}>
          <ul className={bottomNavigationList}>
            <li>
              <Body3 className={copyright}>© CoinTracker {currentYear}</Body3>
            </li>
            {lowerNavItems.map((item, index) => (
              <FooterNavItem
                key={`${item.href}-${index}`}
                {...item}
                variant="secondary"
              />
            ))}
          </ul>
          <FooterSocialList items={socialItems} />
        </FooterGrid>
      </section>
    </footer>
  );
};

const currentYear = new Date().getFullYear();

const primaryNavItems: Array<FooterNavItemProps> = [
  { href: REBRAND_URLS.FEATURES, title: 'How it works' },
  { href: REBRAND_URLS.PRICING, title: 'Pricing' },
  { href: REBRAND_URLS.FULL_SERVICE, title: 'Full service' },
  {
    href: REBRAND_URLS.INTEGRATIONS,
    title: 'Integrations',
  },
  { href: REBRAND_URLS.CALCULATOR, title: 'Profit calculator' },
  { href: REBRAND_URLS.PORTFOLIO_TRACKER, title: 'Portfolio tracker' },
];

const tertiaryNavItems: Array<FooterNavItemProps> = [
  { href: REBRAND_URLS.ABOUT, title: 'About' },
  { href: REBRAND_URLS.BRAND, title: 'Brand' },
  {
    href: REBRAND_URLS.CAREERS,
    title: 'Careers',
    secondaryItem: (
      <Tag secondary caps>
        Hiring
      </Tag>
    ),
  },
  { href: REBRAND_URLS.TESTIMONIALS, title: 'Testimonials' },
];

const secondaryNavItems: Array<FooterNavItemProps> = [
  { href: REBRAND_URLS.PRICES, title: 'Crypto prices' },
  { href: REBRAND_URLS.WALLET, title: 'Crypto wallet lookup' },
  { href: REBRAND_URLS.BLOG, title: 'Blog' },
  { href: REBRAND_URLS.TAX_GUIDE, title: 'Crypto tax guide' },
  { href: REBRAND_URLS.GLOSSARY, title: 'Crypto glossary' },
  { href: REBRAND_URLS.SUPPORT, title: 'Help center' },
];

const lowerNavItems: Array<FooterNavItemProps> = [
  {
    href: REBRAND_URLS.PRIVACY,
    title: 'Privacy Policy',
    TypographyComponent: Body3,
  },
  {
    href: REBRAND_URLS.TERMS,
    title: 'Terms',
    TypographyComponent: Body3,
  },
  {
    href: REBRAND_URLS.DISCLAIMER,
    title: 'Disclaimer',
    TypographyComponent: Body3,
  },
  {
    href: REBRAND_URLS.SECURITY,
    title: 'Security',
    TypographyComponent: Body3,
  },
  {
    href: REBRAND_URLS.STATUS,
    title: 'Status',
    TypographyComponent: Body3,
  },
];

const socialItems: Array<FooterSocialItemProps> = [
  { href: REBRAND_URLS.SOCIAL_MEDIA.X, icon: <X /> },
  { href: REBRAND_URLS.SOCIAL_MEDIA.REDDIT, icon: <Reddit /> },
  { href: REBRAND_URLS.SOCIAL_MEDIA.LINKEDIN, icon: <LinkedIn /> },
  { href: REBRAND_URLS.SOCIAL_MEDIA.FACEBOOK, icon: <Facebook /> },
  { href: REBRAND_URLS.SOCIAL_MEDIA.YOUTUBE, icon: <YouTube /> },
];

export interface FooterNavItemProps {
  href: string;
  variant?: 'primary' | 'secondary';
  title: string;
  secondaryItem?: React.ReactNode;
  className?: string;
  TypographyComponent?: React.ComponentType<{
    children: React.ReactNode;
    className?: string;
  }>;
}

export const FooterNavItem = ({
  href,
  variant = 'primary',
  title,
  secondaryItem,
  className,
  TypographyComponent = Body1,
}: FooterNavItemProps) => {
  return (
    <li className={classNames(navigationListItem, className)}>
      <NavLink href={href} variant={variant}>
        <TypographyComponent>{title}</TypographyComponent>
      </NavLink>
      {secondaryItem}
    </li>
  );
};

export interface FooterNavListProps {
  items: Array<FooterNavItemProps>;
  variant?: 'primary' | 'secondary';
  className?: string;
  listClassName?: string;
  sectionTitle?: string;
}

export const FooterNavList = ({
  items,
  variant = 'primary',
  className,
  listClassName,
  sectionTitle,
}: FooterNavListProps) => {
  return (
    <div>
      {sectionTitle && (
        <Body3 className="text-text-secondary mb-12">{sectionTitle}</Body3>
      )}
      <ul className={classNames(navigationList, listClassName)}>
        {items.map((item, index) => (
          <FooterNavItem
            key={`${item.href}-${index}`}
            {...item}
            variant={variant}
            className={className}
          />
        ))}
      </ul>
    </div>
  );
};

export interface FooterSocialItemProps {
  href: string;
  icon: React.ReactNode;
  className?: string;
}

export const FooterSocialItem = ({
  href,
  icon,
  className,
}: FooterSocialItemProps) => {
  return (
    <li className={className}>
      <NavLink href={href} variant="secondary" socialLink>
        {cloneElement(icon as React.ReactElement, {
          className: socialLink,
        })}
      </NavLink>
    </li>
  );
};

export interface FooterSocialListProps {
  items: Array<FooterSocialItemProps>;
  className?: string;
  listClassName?: string;
}

export const FooterSocialList = ({
  items,
  className,
  listClassName,
}: FooterSocialListProps) => {
  return (
    <ul className={classNames(bottomSocialList, listClassName)}>
      {items.map((item, index) => (
        <FooterSocialItem
          key={`${item.href}-${index}`}
          {...item}
          className={className}
        />
      ))}
    </ul>
  );
};

const FooterGrid = (props: ChildAndClassNameProps) => {
  const { children, className, ...rest } = props;

  return (
    <div className={classNames(className, footerGrid)} {...rest}>
      {children}
    </div>
  );
};

FooterGrid.displayName = 'FooterGrid';
