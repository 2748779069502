import classNames from 'classnames';
import { forwardRef, type ReactNode, type Ref } from 'react';
import { Button, type ButtonProps } from '../Button';
import { Grid } from '../Grid';
import type { HeadingProps } from '../LoggedIn';
import { Heading } from '../LoggedIn';
import { type ChildAndClassNameProps } from '../types';
import { Body1, Body3, H1, H2, H5 } from '../typography';
import {
  buttonStyle,
  container,
  frame,
  styledPrefixTitle,
  styledSubtitle,
  styledTitle,
} from './CTABlock.css';

interface CTABlockProps {
  children: ReactNode;
  className?: string;
  framed?: boolean;
  fullscreen?: boolean;
  noBackground?: boolean;
}

export const CTABlockContainer = forwardRef(
  (
    {
      children,
      className,
      framed,
      fullscreen = true,
      noBackground,
      ...props
    }: CTABlockProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <Grid
      className={classNames(
        container({ framed, fullscreen, noBackground }),
        className,
      )}
      ref={ref}
      {...props}
    >
      {framed ? <div className={frame}>{children}</div> : children}
    </Grid>
  ),
);

CTABlockContainer.displayName = 'CTABlockContainer';

export const CTAFrame = forwardRef(
  (
    { children, className, ...props }: CTABlockProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <div className={classNames(frame, className)} ref={ref} {...props}>
      {children}
    </div>
  ),
);

CTAFrame.displayName = 'CTAFrame';

export const CTAPrefixTitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLParagraphElement>,
  ) => (
    <Body3
      caps
      className={classNames(styledPrefixTitle, className)}
      ref={ref}
      {...props}
    >
      {children}
    </Body3>
  ),
);

CTAPrefixTitle.displayName = 'CTAPrefixTitle';

export const CTATitle = forwardRef(
  (
    { children, className, ...props }: HeadingProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <Heading
      variant="h3"
      className={classNames(styledTitle, className)}
      ref={ref}
      {...props}
    >
      {children}
    </Heading>
  ),
);

CTATitle.displayName = 'CTATitle';

export const LandingCTATitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <H2 className={classNames(styledTitle, className)} ref={ref} {...props}>
      {children}
    </H2>
  ),
);

LandingCTATitle.displayName = 'LandingCTATitle';

export const BigLandingCTATitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <H1 className={classNames(styledTitle, className)} ref={ref} {...props}>
      {children}
    </H1>
  ),
);

BigLandingCTATitle.displayName = 'BigLandingCTATitle';

export const CTASubtitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <Body1
      className={classNames(styledSubtitle, className)}
      ref={ref}
      {...props}
    >
      {children}
    </Body1>
  ),
);

CTASubtitle.displayName = 'CTASubtitle';

export const LandingCTASubtitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <H5 className={classNames(styledSubtitle, className)} ref={ref} {...props}>
      {children}
    </H5>
  ),
);

LandingCTASubtitle.displayName = 'LandingCTASubtitle';

export const CTAButton = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps & ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <Button className={classNames(buttonStyle, className)} ref={ref} {...props}>
      {children}
    </Button>
  ),
);

CTAButton.displayName = 'CTAButton';
