import {
  cn,
  Sidebar as SidebarComponent,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarSeparator,
} from '@cointracker/styleguide';
import RebrandLogoIcon from '@cointracker/styleguide/src/icons/logo-icon.svg?react';
import { Body } from '@cointracker/styleguide/src/LoggedIn/Typography';
import { ScrollArea } from '@cointracker/styleguide/src/sidebar/scroll-area';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@cointracker/styleguide/src/sidebar/tooltip';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AuthenticatedUser, UserContext } from 'src/app/user/context';
import { useCbeDemo } from 'src/pages/Rebrand/CostBasisEngineDemo/cbeDemoContext';
import { useGate } from 'statsig-react';
import { AccountBar } from './AccountBar';
import { DemoArea } from './DemoArea';
import { ReferralButton } from './ReferralButton';
import { StatusButton } from './StatusSection/StatusButton';
import { TaxReadinessStatusButton } from './TaxReadinessSection/TaxReadinessStatusButton';
import {
  getNavigationItems,
  getTaxPageUrl,
  isAuthenticatedUser,
} from './utils';

interface StaticIconSidebarProps {
  className?: string;
}

export const StaticIconSidebar = ({ className }: StaticIconSidebarProps) => {
  const location = useLocation();
  const user = useContext(UserContext);
  const taxReadinessGateOn = useGate('tax_readiness').value;
  const daysLeftToFile = Math.max(0, dayjs('2025-04-16').diff(dayjs(), 'day'));
  const shouldShowTaxReadiness =
    taxReadinessGateOn &&
    user.isAuthenticated &&
    user.country.code === 'US' &&
    daysLeftToFile > 0;
  const { isBroker } = useCbeDemo();

  if (!isAuthenticatedUser(user)) {
    // if user isn't authenticated, we don't render the sidebar as it's never needed in logged out pages.
    return null;
  }
  const taxPageURL = getTaxPageUrl(
    (user as AuthenticatedUser)?.displayUser?.taxYearToUse,
  );
  const navigationItems = getNavigationItems({
    taxPageUrl: taxPageURL,
    currentPathName: location.pathname,
    cbeEnabled: user.enableCbe,
    isBroker,
  });

  return (
    <SidebarComponent
      collapsible="none"
      className={cn(
        'h-full w-[--sidebar-width-icon] border-r border-sidebar-border',
        className,
      )}
    >
      <SidebarHeader className="min-h-space-112 justify-end">
        <NavLink className="self-start" to="/">
          <RebrandLogoIcon className="h-34 w-34 text-text-primary" />
        </NavLink>
      </SidebarHeader>
      <SidebarContent>
        <ScrollArea className="h-full overflow-x-hidden px-32">
          <SidebarMenu className="py-24 md:pt-0">
            <div>
              <SidebarMenuItem className="hidden md:block">
                <StatusButton iconOnly />
                {shouldShowTaxReadiness && <TaxReadinessStatusButton />}
              </SidebarMenuItem>

              <SidebarMenuItem className="hidden md:block">
                <ReferralButton iconOnly />
              </SidebarMenuItem>
              <div className="mt-8 h-1 w-full bg-line-tertiary" />
            </div>
            {navigationItems.map((item) =>
              item.slug === 'break' ? (
                <SidebarSeparator
                  key={item.title}
                  orientation="horizontal"
                  className="-mx-[6px] w-full min-w-30 bg-line-tertiary"
                />
              ) : item.slug === 'demo' ? (
                <DemoArea
                  key={item.slug}
                  title={item.title}
                  subItems={item.subItems}
                  className="p-0"
                />
              ) : (
                <SidebarMenuItem key={item.title}>
                  <NavLink
                    to={item.slug}
                    onClick={(e) => {
                      !item.slug && e.preventDefault();
                    }}
                    aria-label={item.title}
                    className={cn(
                      'flex h-22 cursor-pointer flex-row place-items-center gap-16 text-text-secondary hover:text-text-primary-hover active:text-accent-bold-blue md:gap-8',
                      item.isActive && 'text-text-primary',
                    )}
                  >
                    <Tooltip>
                      <TooltipTrigger>
                        {item.icon({
                          weight: item.isActive ? 'fill' : 'regular',
                        })}
                      </TooltipTrigger>
                      <TooltipContent side="right" align="center">
                        <Body
                          className="text-text-primary-foreground-inverse"
                          variant="body5"
                        >
                          {item.title}
                        </Body>
                        <TooltipArrow width={11} height={5} />
                      </TooltipContent>
                    </Tooltip>
                  </NavLink>
                </SidebarMenuItem>
              ),
            )}
            <SidebarSeparator
              orientation="horizontal"
              className="bg-line-tertiary md:hidden"
            />
          </SidebarMenu>
        </ScrollArea>
      </SidebarContent>
      <SidebarFooter>
        <AccountBar iconOnly />
      </SidebarFooter>
    </SidebarComponent>
  );
};
