import {
  InlineBanner,
  InlineBannerContent,
  Status,
  Textarea,
  ToastContext,
} from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@cointracker/styleguide/src/LoggedIn/Tabs';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { ErrorMessage } from '../PublicAddressForm/ErrorMessage';

import { useApolloClient } from '@apollo/client';
import { Info } from '@phosphor-icons/react';
import { isEmpty } from 'lodash';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetMultiAddressJobStatusQuery,
  GetWalletsQuery,
  Integration,
} from 'src/types/graphql-types';
import { isChromaticEnv } from '../../../../../../../../helpers/isChromatic';
import { AddWalletContext } from '../../context';
import { Instructions } from '../Instructions';
import { IntegrationFormModalFooter } from '../IntegrationFormModalFooter';
import { ViewPermissions } from '../ViewPermissions';
import { WalletConnect } from '../WalletConnectForm';
import { emptyCacheQuery } from '../util';
import useCreateMultiAddressWallet from './hooks/useCreateMultiAddressWallet';
import { ValidationErrors, validatePublicKeys } from './validate';

interface MultiAddressWalletFormProps {
  integration: Integration;
  onCloseModal?: () => void;
  defaultTab?: TABS;
}

export enum TABS {
  WALLET_CONNECT = 'WalletConnect',
  PUBLIC_KEYS = 'PublicKeys',
}

export const MultiAddressWalletForm = (props: MultiAddressWalletFormProps) => {
  const integration = props.integration;
  const {
    metadata: { publicKeys: defaultPublicKeys },

    onSuccess,
  } = useContext(AddWalletContext);

  const [publicKeys, setPublicKeys] = useState<string>(defaultPublicKeys);
  let { defaultTab } = props;
  if (!defaultTab) {
    defaultTab = isEmpty(defaultPublicKeys)
      ? TABS.WALLET_CONNECT
      : TABS.PUBLIC_KEYS;
  }

  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    AddWalletErrors | string
  >();
  const [errors, setErrors] = useState<ValidationErrors>();
  const description = integration.supportsHdWallet
    ? 'Public addresses & xPubs'
    : 'Public addresses';
  const placeholder = integration.supportsHdWallet
    ? 'Paste your public addresses and xPubs here...'
    : 'Paste your public addresses here...';
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showNotification } = useContext(ToastContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  const onPublicKeysChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setPublicKeys(e.target.value);
      setErrors({ ...errors, publicKeys: validatePublicKeys(e.target.value) });
    },
    [errors],
  );

  const client = useApolloClient();
  const { createMultiAddressWallet } = useCreateMultiAddressWallet({
    onCompleted: (data: GetMultiAddressJobStatusQuery) => {
      setIsSubmitting(false);
      const { successMessage, warningMessage, errorMessage } =
        data.multiAddressImportJobStatus;

      if (successMessage) {
        showNotification({
          status: Status.Success,
          message: successMessage,
        });
      }
      if (warningMessage) {
        showNotification({
          status: Status.Info,
          message: warningMessage,
        });
      }
      if (errorMessage) {
        showNotification({
          status: Status.Error,
          message: errorMessage,
        });
      }

      if (data.multiAddressImportJobStatus.createdWallets) {
        const walletsData: GetWalletsQuery =
          client.cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
        const newLocalWallets = [
          ...walletsData.localWallets,
          data.multiAddressImportJobStatus.createdWallets,
        ];

        client.cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, localWallets: newLocalWallets },
        });
      }

      onSuccess(data.multiAddressImportJobStatus.createdWallets?.[0]);
      props.onCloseModal?.();
    },
    onError: (error: string) => {
      setIsSubmitting(false);
      setSubmitErrorMessage(error);
    },
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const publicKeyErrors = validatePublicKeys(publicKeys);
    if (publicKeyErrors) {
      setErrors({ ...errors, publicKeys: publicKeyErrors });
      return;
    }

    setIsSubmitting(true);

    try {
      await createMultiAddressWallet({
        publicKeys,
        accountTypeSlug: integration.slug,
      });
      startFasterPollingForSyncCompletion();
    } catch (error) {
      setIsSubmitting(false);
      setSubmitErrorMessage(error);
    }
  };

  return (
    <>
      <ViewPermissions />

      <Tabs defaultValue={defaultTab}>
        <TabsList>
          <TabsTrigger asChild value={TABS.WALLET_CONNECT}>
            <span>Single Address</span>
          </TabsTrigger>
          <TabsTrigger asChild value={TABS.PUBLIC_KEYS}>
            <span>Multiple Addresses</span>
          </TabsTrigger>
        </TabsList>
        <TabsContent value={TABS.WALLET_CONNECT} className="pt-24">
          {!isChromaticEnv() && (
            <WalletConnect
              integration={integration}
              onCloseModal={props.onCloseModal}
              hideRecommendedWallets
            />
          )}
        </TabsContent>
        <TabsContent value={TABS.PUBLIC_KEYS} className="pt-24">
          <form onSubmit={onSubmit}>
            <div className="flex flex-col gap-24">
              {submitErrorMessage && (
                <InlineBanner variant="negative">
                  <Info size={25} />
                  <InlineBannerContent>
                    <ErrorMessage error={submitErrorMessage} />
                  </InlineBannerContent>
                </InlineBanner>
              )}
              <Textarea
                name="public_keys"
                id="public_keys"
                label={description}
                placeholder={placeholder}
                value={publicKeys}
                onChange={onPublicKeysChange}
                hasError={errors?.publicKeys?.length > 0}
                errorMessage="Please enter at least one public key"
                inputClassName="!h-[100px]"
              />
              <Instructions integration={integration} />
            </div>

            <IntegrationFormModalFooter>
              <Button
                variant="primary"
                loading={isSubmitting}
                type="submit"
                size="large"
              >
                {isSubmitting ? 'Please wait...' : `Add ${integration.name}`}
              </Button>
            </IntegrationFormModalFooter>
          </form>
        </TabsContent>
      </Tabs>
    </>
  );
};
