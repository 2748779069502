import {
  InlineBanner,
  InlineBannerContent,
  Status,
  TextInput,
  ToastContext,
} from '@cointracker/styleguide';
import { Body5, Button } from '@cointracker/styleguide/src/LoggedIn';
import { Wallet } from '@reown/appkit-wallet-button/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Info } from '@phosphor-icons/react';
import {
  useAppKitAccount,
  useAppKitNetwork,
  useDisconnect,
} from '@reown/appkit/react';
import { isEmpty } from 'lodash-es';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsQuery,
  Integration,
  useAddPublicXPubMutation,
} from 'src/types/graphql-types';
import { AddWalletContext } from '../../context';
import { CryptoSelect } from '../CryptoSelect';
import { Instructions } from '../Instructions';
import { IntegrationFormModalFooter } from '../IntegrationFormModalFooter';
import { ErrorMessage } from '../PublicAddressForm/ErrorMessage';
import { ViewPermissions } from '../ViewPermissions';
import { emptyCacheQuery, getWalletInstructions } from '../util';
import {
  ValidationErrors,
  validateCryptoSelector,
  validateWalletAddress,
} from './validate';

interface PublicXPubFormProps {
  integration: Integration;
  cryptoIntegrations: Integration[];
  onCloseModal?: () => void;
}

export const PublicXPubForm = (props: PublicXPubFormProps) => {
  const integration = props.integration;
  const [selectedCrypto, setSelectedCrypto] = useState<Integration>(null);
  const { description, placeholder } = getWalletInstructions(
    selectedCrypto?.slug,
    selectedCrypto?.supportsHdWallet,
  );
  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<AddWalletErrors>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const [errors, setErrors] = useState<ValidationErrors | undefined>();
  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);

  const selectedCryptoRef = useRef<Integration>();
  selectedCryptoRef.current = selectedCrypto;
  const inputRef = useRef<HTMLInputElement>(null);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);
  const { caipNetwork } = useAppKitNetwork();
  const { address: walletAddressAppKit } = useAppKitAccount();
  const onValidateWalletAddress = useCallback(() => {
    const addressErrors = validateWalletAddress(walletAddress);
    setErrors({ ...errors, ...addressErrors });
  }, [walletAddress, errors]);

  const onValidateCryptoSelector = useCallback(
    (integration) => {
      const selectedCryptoErrors = validateCryptoSelector(integration);
      setErrors({ ...errors, ...selectedCryptoErrors });
    },
    [errors],
  );

  const onWalletAddressChange = useCallback(
    (e) => {
      setWalletAddress(e.target.value);
      onValidateWalletAddress();
    },
    [onValidateWalletAddress],
  );

  const updateSelectedCrypto = useCallback(
    (integration: Integration) => {
      setSelectedCrypto(integration);
      onValidateCryptoSelector(integration);
    },
    [onValidateCryptoSelector],
  );

  useEffect(() => {
    if (!walletAddressAppKit) return;
    setWalletAddress(walletAddressAppKit);
  }, [walletAddressAppKit]);

  useEffect(() => {
    if (!caipNetwork || !walletAddressAppKit) return;
    updateSelectedCrypto(
      props.cryptoIntegrations.find(
        (integration) => integration.name === caipNetwork.name,
      ),
    );
  }, [caipNetwork?.name, walletAddressAppKit]);

  const [mutate, { loading }] = useAddPublicXPubMutation({
    onCompleted: (data) => {
      if (data?.addPublicXpub?.success) {
        if (data?.addPublicXpub?.warning) {
          showNotification({
            message: `If you have previously added ${selectedCrypto.info.symbol} transactions manually or marked related transactions as transfers, you may now have duplicate transactions. Please review your ${selectedCrypto.info.symbol} transactions and if necessary undo those changes.`,
            status: Status.Info,
          });
        }
        showNotification({
          message: data.addPublicXpub.success,
          status: Status.Success,
        });
        onSuccess(data.addPublicXpub.createdWallet);
        props.onCloseModal?.();
      } else {
        if (data?.addPublicXpub?.error) {
          setSubmitErrorMessage(data?.addPublicXpub?.error);
        } else {
          setSubmitErrorMessage(AddWalletErrors.UnknownError);
        }
      }
    },
    update: (cache, { data }) => {
      if (!data.addPublicXpub.success) {
        return;
      }
      const walletsData: GetWalletsQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newLocalWallets = [
        ...walletsData.localWallets,
        data.addPublicXpub.createdWallet,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, localWallets: newLocalWallets },
      });
    },
    onError: () => {
      setSubmitErrorMessage(AddWalletErrors.UnknownError);
    },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const addressErrors = validateWalletAddress(walletAddress);
      const cryptoSelectorErrors = validateCryptoSelector(selectedCrypto);

      if (!isEmpty(addressErrors) || !isEmpty(cryptoSelectorErrors)) {
        setErrors({ ...errors, ...addressErrors, ...cryptoSelectorErrors });
        return;
      }

      mutate({
        variables: {
          publicXpubInput: {
            coinSymbol: selectedCrypto.info.symbol,
            cryptoNetwork: selectedCrypto.info.cryptoNetwork,
            xpubKey: walletAddress,
            accountTypeSlug: integration.slug,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      errors,
      integration,
      selectedCrypto,
      mutate,
      walletAddress,
      startFasterPollingForSyncCompletion,
    ],
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-24">
        <ViewPermissions />
        {submitErrorMessage && (
          <InlineBanner variant="negative">
            <Info size={25} />
            <InlineBannerContent>
              <ErrorMessage error={submitErrorMessage} />
            </InlineBannerContent>
          </InlineBanner>
        )}

        <SpecificWalletConnect integrationName={integration.slug} />
        <CryptoSelect
          cryptoIntegrations={props.cryptoIntegrations}
          setSelectedCrypto={updateSelectedCrypto}
          selectedCrypto={selectedCrypto}
          error={errors ? errors.cryptoSelect : null}
        />
        <TextInput
          name="wallet_address"
          value={walletAddress}
          onChange={onWalletAddressChange}
          label={description}
          placeholder={placeholder}
          hasError={errors && errors.walletAddress !== undefined}
          errorMessage={errors?.walletAddress}
          onBlur={onValidateWalletAddress}
          autoFocus={
            !(integration.slug === 'metamask' || integration.slug === 'phantom')
          }
          ref={inputRef}
        />
        <Instructions integration={integration} />
      </div>

      <IntegrationFormModalFooter>
        <Button
          variant="primary"
          loading={loading}
          type="submit"
          size="large"
          data-testid="add-integration-button"
        >
          {loading ? 'Please wait...' : `Add ${integration.name}`}
        </Button>
      </IntegrationFormModalFooter>
    </form>
  );
};

const SpecificWalletConnect = ({
  integrationName,
}: {
  integrationName: string;
}) => {
  const { address: walletAddressAppKit } = useAppKitAccount();
  const { disconnect } = useDisconnect();

  // slug to appkit wallet name mapping that we support
  const VALID_WALLETS_MAPPING: Record<string, Wallet> = {
    'coinbase-wallet': 'coinbase',
    metamask: 'metamask',
    trust: 'trust',
    uniswap: 'uniswap',
    rainbow: 'rainbow',
    'okx-wallet': 'okx',
    ledger: 'ledger',
    zerion: 'zerion',
    'kraken-wallet': 'kraken',
    'robinhood-wallet': 'robinhood',
    exodus: 'exodus',
    jupiter: 'jupiter',
    solflare: 'solflare',
    phantom: 'phantom',
    coin98: 'coin98',
    backpack: 'backpack',
    xverse: 'xverse',
    leather: 'leather',
  };

  if (!VALID_WALLETS_MAPPING[integrationName]) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      {walletAddressAppKit ? (
        <>
          <div className="flex gap-12">
            <appkit-account-button />
            <appkit-network-button />
          </div>
          <button
            type="button"
            onClick={() => {
              disconnect();
            }}
            className="w-fit pl-6 text-text-secondary hover:text-text-primary"
          >
            <Body5>Disconnect</Body5>
          </button>
        </>
      ) : (
        <>
          <Body5 className="text-text-secondary">Connect with</Body5>
          <appkit-wallet-button
            wallet={VALID_WALLETS_MAPPING[integrationName]}
          />
        </>
      )}
    </div>
  );
};
